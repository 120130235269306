.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: auto;
  justify-content: center;
}

.page-item {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}

.page-item:hover {
  cursor: pointer;
}

.page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
}

.pagination-active {
  background-color: #026d93;
  color: #fff;
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
}
